import { useEffect } from "react";
import { useLocation } from "react-router";

export function useAnchorScroll(isLoaded: boolean) {
    const { hash } = useLocation();
    useEffect(() => {
        if (isLoaded && hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);
}

